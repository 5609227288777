const initState = {
  productInformation: {
    MultiplePavingStones: {
      'Camino Muster K': [
        ['Camino Lg Rectangle', 0.73, 2.42, 25, 250, 11],
        ['Camino Square', 0.27, 3.65, 35, 350, 8],
      ],
      'Camino Permeare Muster K': [
        ['Camino Lg Rectangle', 0.73, 2.42, 30, 270, 11],
        ['Camino Square', 0.27, 3.65, 42, 378, 8],
      ],
      'Camino 3 Stone "I"': [
        ['Large Rectangle', 0.37, 2.42, 25, 250, 11],
        ['Square', 0.27, 3.65, 35, 350, 8],
        ['Small Rectangle', 0.36, 4.76, 49, 441, 6],
      ],
      'Camino 2 Stone Running Bond': [
        ['Large Rectangle', 0.6, 2.42, 25, 250, 11],
        ['Square', 0.4, 3.65, 35, 350, 8],
      ],
      'Camino 3 Stone Running Bond': [
        ['Large Rectangle', 0.46, 2.42, 25, 250, 11],
        ['Square', 0.31, 3.65, 35, 350, 8],
        ['Small Rectangle', 0.23, 4.76, 49, 441, 6],
      ],
      'Cobble Slimline Muster K': [
        ['Large Rectangle', 0.73, 3.24, 30, 270, 6],
        ['Square', 0.27, 4.88, 48, 576, 4],
      ],
      'Cobble Slimline Running Bond': [
        ['Large Rectangle', 0.6, 3.24, 30, 270, 6],
        ['Square', 0.4, 4.88, 48, 576, 4],
      ],
      'Double Park / Half Holland Large Basketweave': [
        ['Double Park', 0.89, 1.15, 12, 108, 23],
        ['Half Holland', 0.11, 9.12, 88, 792, 3],
      ],
      'Granite / Leiden Brickweave': [
        ['Park', 0.5, 2.29, 24, 216, 12],
        ['Holland', 0.5, 4.56, 48, 432, 6],
      ],
      'Granite / Leiden 3 Stone': [
        ['Arena', 0.53, 1, 12, 108, 28],
        ['Plaza', 0.35, 1.53, 18, 144, 18],
        ['Park', 0.12, 2.29, 24, 216, 12],
      ],
      'Granite / Leiden 4 Stone': [
        ['Plaza', 0.45, 1.53, 18, 144, 18],
        ['Park', 0.3, 2.29, 24, 216, 12],
        ['Holland', 0.2, 4.56, 48, 432, 6],
        ['Half Holland', 0.05, 9.12, 88, 792, 3],
      ],
      'Granite / Leiden 5 Stone Pattern (Multistone)': [['Multi Stone', 1, 'N/A', 9, 10, 292, '*Multi Stone is sold by the layer']],
      'Granite / Leiden 5 Stone Pattern (Individual Pieces)': [
        ['Arena', 0.41, 1, 12, 108, 28],
        ['Plaza', 0.27, 1.53, 18, 144, 18],
        ['Park', 0.18, 2.29, 24, 216, 12],
        ['Holland', 0.09, 4.56, 48, 432, 6],
        ['Half Holland', 0.05, 9.12, 88, 792, 4],
      ],
      'Granite / Leiden 5 Stone Random': [
        ['Arena', 0.34, 1, 12, 108, 28],
        ['Plaza', 0.26, 1.53, 18, 144, 18],
        ['Park', 0.2, 2.29, 24, 216, 12],
        ['Holland', 0.11, 4.56, 48, 432, 6],
        ['Half Holland', 0.09, 9.12, 88, 792, 4],
      ],
      'Granite / Leiden 3 Stone Small': [
        ['Plaza', 0.5, 1.53, 18, 144, 18],
        ['Park', 0.33, 2.29, 24, 216, 12],
        ['Holland', 0.17, 4.56, 48, 432, 6],
      ],
      'Milana 3 Stone': [
        ['Large Square', 0.4, 0.85, 6, 48, 41],
        ['Large Rectangle', 0.4, 1.28, 12, 96, 26],
        ['Med. Square', 0.2, 1.92, 20, 160, 17.5],
      ],
      'Milana 3 Stone Small': [
        ['Large Rectangle', 0.5, 1.28, 12, 96, 26],
        ['Med. Square', 0.33, 1.92, 20, 160, 17.5],
        ['Small Rectangle', 0.17, 3.87, 35, 280, 8.3],
      ],
      'Milana 4 Stone': [
        ['Large Square', 0.4, 0.85, 6, 48, 41],
        ['Large Rectangle', 0.26, 1.28, 12, 96, 26],
        ['Med. Square', 0.18, 1.92, 20, 160, 17.5],
        ['Small Rectangle', 0.18, 3.87, 35, 280, 8.3],
      ],
      'Milana Muster K': [
        ['Large Rectangle', 0.73, 1.28, 12, 96, 26],
        ['Med. Square', 0.27, 1.92, 20, 160, 17.5],
      ],
      'Milana Running Bond': [
        ['Large Rectangle', 0.6, 1.28, 12, 96, 26],
        ['Med. Square', 0.4, 1.92, 20, 160, 17.5],
      ],
      'Milana Random': [
        ['Large Square', 0.38, 0.85, 6, 48, 41],
        ['Large Rectangle', 0.27, 1.28, 12, 96, 26],
        ['Med. Square', 0.22, 1.92, 20, 160, 17.5],
        ['Small Rectangle', 0.12, 3.87, 35, 280, 8.3],
      ],
      'La Lastra 3 Stone Random': [
        ['Large Square ', 0.43, 0.25, 2, 18, 94],
        ['Large Rectangle', 0.32, 0.34, 4, 36, 71],
        ['Med. Square', 0.24, 0.45, 4, 36, 54],
      ],
      'La Pietra 5 Stone': [
        ['Grande', 0.41, 0.33, 2, 16, 84],
        ['Large Rectangle', 0.27, 0.5, 4, 32, 56.7],
        ['Medium Square', 0.18, 0.75, 6, 48, 38],
        ['Small Rectangle', 0.09, 1.52, 12, 96, 19],
        ['Small Square', 0.05, 2.94, 24, 192, 9.5],
      ],
      'La Pietra Quattro Random': [['Quattro', 1, 'N/A', 12, 8, 331.5, '*Quattro is sold by the layer']],
      'La Pietra 3 Stone Running Bond': [
        ['Large Rectangle', 0.5, 0.5, 4, 32, 56.7],
        ['Medium Square', 0.33, 0.75, 6, 48, 38],
        ['Small Rectangle', 0.17, 1.52, 12, 96, 19],
      ],
      'La Pietra Italia': [
        ['Large Rectangle', 0.465, 0.5, 4, 32, 56.7],
        ['Medium Square', 0.31, 0.75, 6, 48, 38],
        ['Small Rectangle', 0.15, 1.52, 12, 96, 19],
        ['Small Square', 0.075, 2.94, 24, 192, 9.5],
      ],
      'La Pietra Modified Marseilles': [
        ['Large Rectangle', 0.3, 0.5, 4, 32, 56.7],
        ['Medium Square', 0.4, 0.75, 6, 48, 38],
        ['Small Rectangle', 0.2, 1.52, 12, 96, 19],
        ['Small Square', 0.1, 2.94, 24, 192, 9.5],
      ],
      'La Pietra Muster K': [
        ['Large Rectangle', 0.73, 0.5, 4, 32, 56.7],
        ['Medium Square', 0.27, 0.75, 6, 48, 38],
      ],
      'La Pietra Opus II': [
        ['Large Rectangle', 0.5, 0.5, 4, 32, 56.7],
        ['Medium Square', 0.22, 0.75, 6, 48, 38],
        ['Small Rectangle', 0.22, 1.52, 12, 96, 19],
        ['Small Square', 0.06, 2.94, 24, 192, 9.5],
      ],
      'La Pietra Palatial Versailles': [
        ['Large Rectangle', 0.34, 0.5, 4, 32, 56.7],
        ['Medium Square', 0.44, 0.75, 6, 48, 38],
        ['Small Rectangle', 0.11, 1.52, 12, 96, 19],
        ['Small Square', 0.11, 2.94, 24, 192, 9.5],
      ],
      'La Pietra 2 Stone Running Bond': [
        ['Large Rectangle', 0.6, 0.5, 4, 32, 56.7],
        ['Medium Square', 0.4, 0.75, 6, 48, 38],
      ],
      'La Pietra Moderna 5 Stone': [
        ['Grande', 0.41, 0.33, 2, 20, 84],
        ['Large Rectangle', 0.27, 0.5, 4, 40, 56.7],
        ['Medium Square', 0.18, 0.75, 6, 60, 38],
        ['Small Rectangle', 0.09, 1.52, 12, 120, 19],
        ['Small Square', 0.05, 2.94, 24, 240, 9.5],
      ],
      'La Pietra Quattro Moderna Random': [['Quattro', 1, 'N/A', 12, 8, 331.5, '*Quattro is sold by the layer']],
      'La Pietra Moderna 3 Stone Running Bond': [
        ['Large Rectangle', 0.5, 0.5, 4, 40, 56.7],
        ['Medium Square', 0.33, 0.75, 6, 60, 38],
        ['Small Rectangle', 0.17, 1.52, 12, 120, 19],
      ],
      'La Pietra Moderna Italia': [
        ['Large Rectangle', 0.465, 0.5, 4, 40, 56.7],
        ['Medium Square', 0.31, 0.75, 6, 60, 38],
        ['Small Rectangle', 0.15, 1.52, 12, 120, 19],
        ['Small Square', 0.075, 2.94, 24, 240, 9.5],
      ],
      'La Pietra Moderna Modified Marseilles': [
        ['Large Rectangle', 0.3, 0.5, 4, 40, 56.7],
        ['Medium Square', 0.4, 0.75, 6, 60, 38],
        ['Small Rectangle', 0.2, 1.52, 12, 120, 19],
        ['Small Square', 0.1, 2.94, 24, 240, 9.5],
      ],
      'La Pietra Moderna Muster K': [
        ['Large Rectangle', 0.73, 0.5, 4, 40, 56.7],
        ['Medium Square', 0.27, 0.75, 6, 60, 38],
      ],
      'La Pietra Moderna Opus II': [
        ['Large Rectangle', 0.5, 0.5, 4, 40, 56.7],
        ['Medium Square', 0.22, 0.75, 6, 60, 38],
        ['Small Rectangle', 0.22, 1.52, 12, 120, 19],
        ['Small Square', 0.06, 2.94, 24, 240, 9.5],
      ],
      'La Pietra Moderna Palatial Versailles': [
        ['Large Rectangle', 0.34, 0.5, 4, 40, 56.7],
        ['Medium Square', 0.44, 0.75, 6, 60, 38],
        ['Small Rectangle', 0.11, 1.52, 12, 120, 19],
        ['Small Square', 0.11, 2.94, 24, 240, 9.5],
      ],
      'La Pietra Moderna 2 Stone Running Bond': [
        ['Large Rectangle', 0.6, 0.5, 4, 40, 56.7],
        ['Medium Square', 0.4, 0.75, 6, 60, 38],
      ],
      'Leiden 6 Stone': [
        ['Arena', 0.3, 1, 12, 108, 28],
        ['Double Park', 0.27, 1.15, 12, 108, 23],
        ['Plaza', 0.2, 1.53, 18, 144, 18],
        ['Park', 0.13, 2.29, 24, 216, 12],
        ['Holland', 0.07, 4.56, 48, 432, 6],
        ['Half Holland', 0.03, 9.12, 88, 792, 3],
      ],
      'Leiden Paladio': [
        ['Arena', 0.3, 1, 12, 108, 28],
        ['Double Park', 0.27, 1.15, 12, 108, 23],
        ['Plaza', 0.2, 1.53, 18, 144, 18],
        ['Park', 0.14, 2.29, 24, 216, 12],
        ['Holland', 0.06, 4.56, 48, 432, 6],
        ['Half Holland', 0.03, 9.12, 88, 792, 3],
      ],
      Linen: [
        ['Arena', 0.8, 1, 12, 108, 28],
        ['City Square', 0.2, 4.09, 42, 378, 8],
      ],
      'Park / Plaza Muster K': [
        ['Plaza', 0.73, 1.53, 18, 144, 18],
        ['Park', 0.27, 2.29, 24, 216, 12],
      ],
      'Park / Plaza Running Bond': [
        ['Plaza', 0.6, 1.53, 18, 144, 18],
        ['Park', 0.4, 2.29, 24, 216, 12],
      ],
      'Roma Stone': [['Roma Stone', 1, 'N/A', 10, 9, 242, '*Roma Stone is sold by the layer']],
    },
    Borders: {
      Arena: [
        ['Standard', 1, 11.85, 12, 108, 28],
        ['Granite', 1, 11.85, 12, 108, 28],
      ],
      'Camino Lg Rectangle (Sailor)': [
        ['Cobble', 2.42, 9.37, 25, 250, 11],
        ['Roca', 2.42, 9.37, 25, 250, 11],
      ],
      'Camino Lg Rectangle (Soldier)': [
        ['Cobble', 2.42, 6.22, 25, 250, 11],
        ['Roca', 2.42, 6.22, 25, 250, 11],
      ],
      'Camino Sm Rectangle (Sailor)': [
        ['Cobble', 4.76, 6.22, 49, 441, 6],
        ['Roca', 4.76, 6.22, 49, 441, 6],
      ],
      'Camino Sm Rectangle (Soldier)': [
        ['Cobble', 4.76, 4.65, 49, 441, 6],
        ['Roca', 4.76, 4.65, 49, 441, 6],
      ],
      'Camino Square': [
        ['Cobble', 3.65, 6.22, 35, 350, 8],
        ['Roca', 3.65, 6.22, 35, 350, 8],
      ],
      'Cobble Slimline Rectangle (Sailor)': [['', 3.24, 8.19, 30, 360, 3.72]],
      'Cobble Slimline Rectangle (Soldier)': [['', 3.24, 5.43, 30, 360, 3.72]],
      'Cobble Slimline Square': [['', 4.88, 5.43, 48, 576, 3.72]],
      'Double Park (Sailor)': [['', 1.15, 15.59, 12, 108, 23]],
      'Double Park (Soldier)': [['', 1.15, 7.79, 12, 108, 23]],
      'Half Holland': [
        ['Standard', 9.12, 3.86, 88, 792, 3],
        ['Roca', 9.12, 3.86, 88, 792, 3],
        ['Granite', 9.12, 3.86, 88, 792, 3],
      ],
      'Holland (Sailor)': [
        ['Standard', 4.56, 7.79, 54, 486, 6],
        ['Roca', 4.56, 7.79, 54, 486, 6],
        ['Granite', 4.56, 7.79, 48, 432, 6],
      ],
      'Holland (Soldier)': [
        ['Standard', 4.56, 3.86, 54, 486, 6],
        ['Roca', 4.56, 3.86, 54, 486, 6],
        ['Granite', 4.56, 3.86, 48, 432, 6],
      ],
      'Holland Slimline (Sailor)': [['', 4.51, 7.79, 45, 540, 4]],
      'Holland Slimline (Soldier)': [['', 4.51, 3.86, 45, 540, 4]],
      'Milana Small Rectangle (Sailor)': [
        ['Standard', 3.84, 8.66, 35, 280, 8.3],
        ['Moderna', 3.84, 8.66, 35, 280, 8.3],
      ],
      'Milana Small Rectangle (Soldier)': [
        ['Standard', 3.84, 4.33, 35, 280, 8.3],
        ['Moderna', 3.84, 4.33, 35, 280, 8.3],
      ],
      'Monaco Coping Stone': [['', 2, 5.9, 40, 160, 13.26]],
      Park: [
        ['Standard', 2.29, 7.79, 24, 216, 12],
        ['Cobble', 2.29, 7.79, 24, 216, 12],
        ['Granite', 2.29, 7.79, 24, 216, 12],
        ['Roca', 2.29, 7.79, 24, 216, 12],
      ],
      'Plaza (Sailor)': [
        ['Standard', 1.53, 11.73, 18, 144, 18],
        ['Cobble', 1.53, 11.73, 18, 144, 18],
        ['Granite', 1.53, 11.73, 18, 144, 18],
      ],
      'Plaza (Soldier)': [
        ['Standard', 1.53, 7.79, 18, 144, 18],
        ['Cobble', 1.53, 7.79, 18, 144, 18],
        ['Granite', 1.53, 7.79, 18, 144, 18],
      ],
    },
    MultipleWallBlocks: {
      'Chateau 3 Stone Running Bond (Small)': [
        ['300s', 0.5, 3.1, 20, 100, 28.5],
        ['200s', 0.33, 4.66, 30, 150, 19],
        ['100s', 0.17, 9.3, 60, 300, 9.5],
      ],
      'Chateau 3 Stone Running Bond (Large)': [
        ['400s', 0.5, 2.33, 15, 75, 38],
        ['300s', 0.33, 3.1, 20, 100, 28.5],
        ['200s', 0.17, 4.66, 30, 150, 19],
      ],
      'Chateau 4 Stone Running Bond': [
        ['400s', 0.35, 2.33, 15, 75, 38],
        ['300s', 0.4, 3.1, 20, 100, 28.5],
        ['200s', 0.2, 4.66, 30, 150, 19],
        ['100s', 0.05, 9.3, 60, 300, 9.5],
      ],
      'Chateau 4 Stone Random': [
        ['400s', 0.3, 2.33, 15, 75, 38],
        ['300s', 0.4, 3.1, 20, 100, 28.5],
        ['200s', 0.25, 4.66, 30, 150, 19],
        ['100s', 0.05, 9.3, 60, 300, 9.5],
      ],
      'Chateau Diviso 3 Stone Running Bond (Large)': [
        ['400s', 0.5, 2.33, 15, 75, 38],
        ['300s', 0.33, 3.1, 20, 100, 28.5],
        ['200s', 0.17, 4.66, 30, 150, 19],
      ],
      'Chateau Moderna 3 Stone Running Bond (Large)': [
        ['400s', 0.5, 2.33, 24, 72, 38],
        ['300s', 0.33, 3.1, 32, 96, 28.5],
        ['200s', 0.17, 4.66, 48, 144, 19],
      ],
    },
    SinglePavingStones: {
      'Arena / City Square': [
        ['Arena', '12 x 12', 1, 12, 108, 28],
        ['City Square', '6 x 6', 4.09, 42, 378, 8],
      ],
      Camino: [
        ['Large Rectangle', '6.2 x 9.3', 2.42, 25, 250, 11],
        ['Square', '6.2 x 6.2', 3.65, 35, 350, 8],
        ['Small Rectangle', '4.6 x 6.2', 4.76, 49, 441, 6],
      ],
      'Camino Permeare': [
        ['Large Rectangle', '6.2 x 9.3', 2.42, 30, 270, 10.9],
        ['Square', '6.2 x 6.2', 3.65, 42, 378, 7.3],
      ],
      'Cobble Slimline': [
        ['Large Rectangle', '8 x 5.5', 3.24, 30, 270, 6],
        ['Square', '5.5 x 5.5', 4.88, 48, 576, 4],
      ],
      'Double Park': [['Double Park', '8 x 16', 1.15, 12, 108, 23]],
      'Granite Stone': [
        ['Half Holland', '4 x 4', 9.12, 88, 792, 3],
        ['Holland', '4 x 8', 4.56, 48, 432, 6],
        ['Park', '8 x 8', 2.29, 24, 216, 12],
        ['Plaza', '8 x 12', 1.53, 18, 144, 18],
        ['Arena', '12 x 12', 1, 12, 108, 28],
      ],
      'Holland Stone / Half Holland Stone': [
        ['Half Holland', '4 x 4', 9.12, 88, 792, 3],
        ['Holland', '4 x 8', 4.56, 48, 432, 6],
      ],
      'Holland Slimline': [['Holland', '4 x 8', 4.51, 45, 540, 4]],
      'Holland / Park Permeare': [
        ['Holland', '4 x 8', 4.56, 54, 486, 5.6],
        ['Park', '8 x 8', 2.29, 24, 216, 12],
      ],
      Milana: [
        ['Large Square', '13 x 13', 0.85, 6, 48, 41],
        ['Large Rectangle', '13 x 8.5', 1.28, 12, 96, 26],
        ['Med. Square', '8.5 x 8.5', 1.92, 20, 160, 17.5],
        ['Small Rectangle', '8.5 x 4.25', 3.87, 35, 280, 8.3],
      ],
      'La Lastra': [
        ['Med. Square', '18 x 18', 0.45, 4, 36, 54],
        ['Large Rectangle', '18 x 24', 0.34, 4, 36, 71],
        ['Large Square', '24 x 24', 0.25, 2, 18, 94],
      ],
      'La Pietra': [
        ['Small Square', '7 x 7', 2.94, 24, 192, 9.5],
        ['Small Rectangle', '7 x 14', 1.52, 12, 96, 19],
        ['Medium Square', '14 x 14', 0.75, 6, 48, 38],
        ['Large Rectangle', '14 x 21', 0.5, 4, 32, 56.7],
        ['Grande', '21 x 21', 0.33, 2, 16, 84],
      ],
      'La Pietra Moderna': [
        ['Small Square', '7 x 7', 2.94, 24, 240, 9.5],
        ['Small Rectangle', '7 x 14', 1.52, 12, 120, 19],
        ['Medium Square', '14 x 14', 0.75, 6, 60, 38],
        ['Large Rectangle', '14 x 21', 0.5, 4, 40, 56.7],
        ['Grande', '21 x 21', 0.33, 2, 20, 84],
      ],
      'Park / Plaza': [
        ['Park', '8 x 8', 2.29, 24, 216, 12],
        ['Plaza', '8 x 12', 1.53, 18, 144, 18],
      ],
      'Turf Stone': [['Turf Stone', '24 x 16', 0.38, 4, 20, 73]],
      Villa: [['Villa', '18 x 3', 2.86, 28, 140, 16]],
      'VS-5': [['Drain Pave', '8 x 8', 2.35, 20, 120, 2.35]],
    },
    SingleWallBlocks: {
      Chateau: [
        ['100s', '4 x 4 x 8', 9.3, 60, 300, 9.5],
        ['200s', '4 x 8 x 8', 4.63, 30, 150, 19],
        ['300s', '4 x 8 x 12', 3.1, 20, 100, 28.5],
        ['400s', '4 x 8 x 16', 2.33, 15, 75, 38],
        ['Beveled', '4 x 8 x 5', 4.06, 36, 180, 16],
      ],
      'Chateau Diviso': [
        ['Diviso 200', '4 x 8 x 8', 4.63, 30, 150, 19],
        ['Diviso 200 Corner', '4 x 8 x 8', 4.63, 30, 150, 19],
        ['Diviso 300', '4 x 8 x 12', 3.1, 20, 100, 28.5],
        ['Diviso 300 Corner', '4 x 8 x 12', 3.1, 20, 100, 28.5],
        ['Diviso 400', '4 x 8 x 16', 2.33, 15, 75, 38],
        ['Diviso Bevel', '4 x 8 x 8 x 12', 3.1, 28, 140, 16],
      ],
      'Chateau Moderna': [
        ['200s', '4 x 8 x 8', 4.63, 48, 144, 19],
        ['300s', '4 x 8 x 12', 3.1, 32, 96, 28.5],
        ['400s', '4 x 8 x 16', 2.33, 24, 72, 38],
      ],
      'Gradino Wall Block': [['Gradino Wall Block', '6 x 16 x 12', 1.5, 8, 48, 64]],
      Murata: [
        ['Variable', '8 x 16 x 12', 1.15, 8, 40, 60],
        ['Standard', '8 x 16 x 12', 1.15, 8, 40, 60],
        ['Corner', '8 x 16 x 8', 1.15, 12, 36, 83],
        ['Solido', '8 x 16 x 12', 1.15, 8, 24, 112],
      ],
      'Nursery Stones': [
        ['Jumbo Nursery', '6 x 16 x 12', 1.5, 9, 54, 55],
        ['Nursery', '4 x 12 x 8', 3, 16, 96, 18],
      ],
      'Slope Block': [['Standard', '5.5 x 12 x 12', 2.08, 30, 90, 12]],
      Tegula: [
        ['Beveled', '7 x 8 x 6.5', 2.35, 30, 90, 33],
        ['Standard', '7 x 9.5 x 6.5', 2.35, 30, 90, 33],
      ],
      /*'Western Manor Flat Face': [['Flat Face', '6 x 16 x 12', 1.5, 9, 54, 68]],
      'Western Manor Radius Face': [['Radius Face', '6 x 16 x 12', 1.5, 9, 54, 68]],*/
    },
    Rotundo: {
      Rotundo: [
        [0, 0, 0, 0, 0, 0],
        [1, 0.67, 'C', 2, 0.0, 0.0],
        [2, 1.98, 1, 8, 0.0, 0.0],
        [3, 3.31, 2, 8, 0.0, 0.0],
        [4, 4.63, 3, 8, 0.0, 0.0],
        [5, 4.63, 3, 8, 0.0, 0.0],
        [6, 5.95, 4, 8, 0.0, 0.0],
        [7, 7.27, 5, 8, 0.0, 0.0],
        [8, 8.6, 6, 8, 0.0, 0.0],
        [9, 9.94, 7, 9, 0.0, 0.0],
        [10, 9.94, 7, 9, 0.0, 0.0],
        [11, 11.27, 8, 12, 0.0, 0.0],
        [12, 12.58, 9, 12, 0.0, 0.0],
        [13, 12.58, 9, 12, 0.0, 0.0],
        [14, 13.92, 10, 15, 20.0, 0.1],
        [15, 15.25, 11, 17, 42.0, 0.2],
        [16, 16.5, 12, 20, 66.0, 0.3],
        [17, 16.5, 12, 20, 66.0, 0.3],
        [18, 17.83, 13, 23, 92.0, 0.4],
        [19, 19.21, 14, 26, 120.0, 0.6],
        [20, 20.54, 15, 28, 124.0, 0.6],
        [21, 20.54, 15, 28, 124.0, 0.6],
        [22, 21.87, 19, 32, 132.0, 0.6],
        [23, 23.2, 17, 34, 137.0, 0.6],
        [24, 24.53, 18, 36, 142.0, 0.7],
        [25, 25.86, 19, 38, 147.0, 0.7],
        [26, 25.86, 19, 38, 147.0, 0.7],
        [27, 27.19, 20, 43, 158.0, 0.7],
        [28, 28.52, 21, 46, 164.0, 0.8],
        [29, 28.52, 21, 46, 164.0, 0.8],
        [30, 29.85, 22, 52, 176.0, 0.8],
      ],
      'Roca Rotundo': [
        [0, 0, 0, 0, 0, 0],
        [1, 0.67, 'C', 2, 0.0, 0.0],
        [2, 1.98, 1, 8, 0.0, 0.0],
        [3, 3.31, 2, 8, 0.0, 0.0],
        [4, 4.63, 3, 8, 0.0, 0.0],
        [5, 4.63, 3, 8, 0.0, 0.0],
        [6, 5.95, 4, 8, 0.0, 0.0],
        [7, 7.27, 5, 8, 0.0, 0.0],
        [8, 8.6, 6, 8, 0.0, 0.0],
        [9, 9.94, 7, 9, 0.0, 0.0],
        [10, 9.94, 7, 9, 0.0, 0.0],
        [11, 11.27, 8, 12, 0.0, 0.0],
        [12, 12.58, 9, 12, 0.0, 0.0],
        [13, 12.58, 9, 12, 0.0, 0.0],
        [14, 13.92, 10, 15, 20.0, 0.1],
        [15, 15.25, 11, 17, 42.0, 0.2],
        [16, 16.5, 12, 20, 66.0, 0.3],
        [17, 16.5, 12, 20, 66.0, 0.3],
        [18, 17.83, 13, 23, 92.0, 0.4],
        [19, 19.21, 14, 26, 120.0, 0.6],
        [20, 20.54, 15, 28, 124.0, 0.6],
        [21, 20.54, 15, 28, 124.0, 0.6],
        [22, 21.87, 19, 32, 132.0, 0.6],
        [23, 23.2, 17, 34, 137.0, 0.6],
        [24, 24.53, 18, 36, 142.0, 0.7],
        [25, 25.86, 19, 38, 147.0, 0.7],
        [26, 25.86, 19, 38, 147.0, 0.7],
        [27, 27.19, 20, 43, 158.0, 0.7],
        [28, 28.52, 21, 46, 164.0, 0.8],
        [29, 28.52, 21, 46, 164.0, 0.8],
        [30, 29.85, 22, 52, 176.0, 0.8],
      ],
      'Alpine Rotundo': [
        [0, 0, 0, 0, 0, 0, 0],
        [1, 1.08, 'C', 4, 0, 0, 0],
        [2, 1.08, 'C', 4, 0, 0, 0],
        [3, 3.25, 1, 4, 0, 0, 0],
        [4, 3.25, 1, 4, 0, 0, 0],
        [5, 5.42, 2, 4, 0, 0, 0],
        [6, 5.42, 2, 4, 0, 0, 0],
        [7, 7.58, 3, 4, 23, 0, 0],
        [8, 7.58, 3, 4, 23, 0, 0],
        [9, 9.75, 4, 4, 23, 17, 0],
        [10, 9.75, 4, 4, 23, 17, 0],
        [11, 10.92, 5, 4, 44, 38, 0],
        [12, 10.92, 5, 4, 44, 38, 0],
        [13, 10.92, 5, 4, 44, 38, 0],
        [14, 14.08, 6, 4, 66, 46, 14],
        [15, 14.08, 6, 4, 66, 46, 14],
        [16, 16.25, 7, 4, 90, 46, 38],
        [17, 16.25, 7, 4, 90, 46, 38],
        [18, 18.42, 8, 4, 117, 47, 65],
        [19, 18.42, 8, 4, 117, 47, 65],
        [20, 20.59, 9, 4, 148, 49, 94],
        [21, 20.59, 9, 4, 148, 49, 94],
        [22, 22.76, 10, 4, 182, 49, 128],
        [23, 22.76, 10, 4, 182, 49, 128],
        [24, 22.76, 10, 4, 182, 49, 128],
        [25, 24.93, 11, 4, 219, 49, 165],
        [26, 24.93, 11, 4, 219, 49, 165],
        [27, 27.1, 12, 4, 219, 159, 165],
        [28, 27.1, 12, 4, 219, 159, 165],
        [29, 29.27, 13, 4, 219, 278, 165],
        [30, 31.44, 14, 4, 219, 408, 165],
      ],
    },
    FireFeatures: {
      'Deschutes Fireplace Kit': [
        ['1 Woodbox', 3, 6780],
        ['2 Woodboxes', 4, 7910],
        ['No Woodbox', 2, 5650],
      ],
      'Rogue Fireplace Kit': [
        ['1 Woodbox', 4, 10000],
        ['2 Woodboxes', 5, 11600],
        ['No Woodbox', 3, 8400],
      ],
      '50" Chateau Fire Pit Kit': [['', 1, 960]],
      '50" Chateau Diviso Fire Pit Kit': [['', 1, 897]],
      '40" Tegula Fire Pit Kit': [['', 1, 1452]],
    },
    Steps: {
      'Tegula Step': [
        ['18" Step', '7 x 9.5 x 6.5', 4, 30, 90, 33],
        ['27" Step', '7 x 9.5 x 6.5', 6, 30, 90, 33],
        ['36" Step', '7 x 9.5 x 6.5', 8, 30, 90, 33],
        ['45" Step', '7 x 9.5 x 6.5', 10, 30, 90, 33],
      ],
      'Gradino Step Block': [
        ['16" Step', '6 x 16 x 12', 2, 10, 40, 88],
        ['32" Step', '6 x 16 x 12', 4, 10, 40, 88],
        ['48" Step', '6 x 16 x 12', 6, 10, 40, 88],
        ['64" Step', '6 x 16 x 12', 8, 10, 40, 88],
      ],
      'Murata Variable Step': [
        ['16" Step', '8 x 16 x 12', 2, 8, 40, 60],
        ['32" Step', '8 x 16 x 12', 4, 8, 40, 60],
        ['48" Step', '8 x 16 x 12', 6, 8, 40, 60],
        ['64" Step', '8 x 16 x 12', 8, 8, 40, 60],
      ],
      'Murata Step Tread': [
        ['36" Step', '7 x 16 x 36', 1.75, 3, 9, 306],
        ['48" Step', '7 x 16 x 48', 1.75, 2, 6, 398],
      ],
    },
    Caps: {
      'Chateau Diviso Cap': [['Diviso Cap', '12.5 x 11 x 7 x 3', 1.2, 12, 84]],
      'Jumbo / Nursery Caps': [
        ['Jumbo Cap', '3 x 18 x 12.5', 0.77, 9, 72, 40],
        ['Nursery Cap', '3 x 14 x 10', 1.09, 16, 96, 21],
      ],
      'Murata Capello': [
        ['MC4035', '15.75 x 14 x 3', 0.764, 6, 30, 54],
        ['MC8035', '31.5 x 14 x 3', 0.384, 3, 15, 106],
        ['MC6060', '24 x 24 x 3', 0.5, 2, 10, 135],
        ['MC7070', '28 x 28 x 3', 0.428, 1, 5, 180],
      ],
      'Versa Cap': [['Versa Cap', '3 x 18 x 12.5', 0.77, 6, 48, 47]],
    },
  },
  colors: {
    MultiplePavingStones: {
      'Camino Muster K': [
        {
          Cobble: ['Pewter', 'Charcoal', 'Cambridge Blend', 'Boston Blend', 'Jamestown Blend', 'Britewood Blend', 'Columbia Blend', 'Umbria Blend'],
        },
        { Roca: ['Jamestown Blend', 'Columbia Blend'] },
      ],
      'Camino Permeare Muster K': ['Cambridge Blend', 'Jamestown Blend', 'Columbia Blend'],
      'Park / Plaza Muster K': [
        {
          Standard: ['Cambridge Blend', 'Jamestown Blend', 'Boston Blend', 'Pewter', 'Charcoal'],
        },
        {
          Cobble: ['Pewter', 'Charcoal', 'Cambridge Blend', 'Jamestown Blend', 'Charleston Blend', 'Columbia Blend'],
        },
        {
          Granite: ['Cambridge Blend', 'Jamestown Blend', 'Columbia Blend', 'NW Slate Blend', 'Charcoal'],
        },
        { Roca: ['Cambridge Blend', 'Jamestown Blend', 'Columbia Blend'] },
      ],
      'Cobble Slimline Muster K': ['Cambridge Blend', 'Jamestown Blend', 'Columbia Blend'],
      'Camino 3 Stone "I"': [
        {
          Cobble: ['Pewter', 'Charcoal', 'Cambridge Blend', 'Boston Blend', 'Jamestown Blend', 'Britewood Blend', 'Columbia Blend', 'Umbria Blend'],
        },
        { Roca: ['Jamestown Blend', 'Columbia Blend'] },
      ],
      'Camino 2 Stone Running Bond': [
        {
          Cobble: ['Pewter', 'Charcoal', 'Cambridge Blend', 'Boston Blend', 'Jamestown Blend', 'Britewood Blend', 'Columbia Blend', 'Umbria Blend'],
        },
        { Roca: ['Jamestown Blend', 'Columbia Blend'] },
      ],
      'Camino 3 Stone Running Bond': [
        {
          Cobble: ['Pewter', 'Charcoal', 'Cambridge Blend', 'Boston Blend', 'Jamestown Blend', 'Britewood Blend', 'Columbia Blend', 'Umbria Blend'],
        },
        { Roca: ['Jamestown Blend', 'Columbia Blend'] },
      ],
      'Park / Plaza Running Bond': [
        {
          Standard: ['Cambridge Blend', 'Jamestown Blend', 'Charleston Blend', 'Columbia Blend'],
        },
        {
          Cobble: ['Pewter', 'Charcoal', 'Cambridge Blend', 'Jamestown Blend', 'Charleston Blend', 'Columbia Blend'],
        },
        {
          Granite: ['Cambridge Blend', 'Jamestown Blend', 'Columbia Blend', 'NW Slate Blend', 'Charcoal'],
        },
        { Roca: ['Cambridge Blend', 'Jamestown Blend', 'Columbia Blend'] },
      ],
      'Double Park / Half Holland Large Basketweave': ['Cambridge Blend', 'Charcoal', 'Pewter', 'Jamestown Blend'],
      'Leiden 6 Stone': ['Cambridge Blend', 'Jamestown Blend'],
      'Leiden Paladio': ['Cambridge Blend', 'Jamestown Blend'],
      'Cobble Slimline Running Bond': ['Cambridge Blend', 'Jamestown Blend', 'Columbia Blend'],
      'Granite / Leiden Brickweave': [{ Granite: ['Cambridge Blend', 'Jamestown Blend', 'Columbia Blend'] }, { Leiden: ['Cambridge Blend', 'Jamestown Blend'] }],
      'Granite / Leiden 3 Stone Small': [{ Granite: ['Cambridge Blend', 'Jamestown Blend', 'Columbia Blend'] }, { Leiden: ['Cambridge Blend', 'Jamestown Blend'] }],
      'Granite / Leiden 3 Stone': [{ Granite: ['Cambridge Blend', 'Jamestown Blend', 'Columbia Blend'] }, { Leiden: ['Cambridge Blend', 'Jamestown Blend'] }],
      'Granite / Leiden 4 Stone': [{ Granite: ['Cambridge Blend', 'Jamestown Blend', 'Columbia Blend'] }, { Leiden: ['Cambridge Blend', 'Jamestown Blend'] }],
      'Granite / Leiden 5 Stone Pattern (Multistone)': [{ Granite: ['Cambridge Blend', 'Jamestown Blend', 'Columbia Blend'] }, { Leiden: ['Cambridge Blend', 'Jamestown Blend'] }],
      'Granite / Leiden 5 Stone Pattern (Individual Pieces)': [{ Granite: ['Cambridge Blend', 'Jamestown Blend', 'Columbia Blend'] }, { Leiden: ['Cambridge Blend', 'Jamestown Blend'] }],
      'Granite / Leiden 5 Stone Random': [{ Granite: ['Cambridge Blend', 'Jamestown Blend', 'Columbia Blend'] }, { Leiden: ['Cambridge Blend', 'Jamestown Blend'] }],
      'Granite / Leiden 3 Stone Running Bond': [{ Granite: ['Cambridge Blend', 'Jamestown Blend', 'Columbia Blend'] }, { Leiden: ['Cambridge Blend', 'Jamestown Blend'] }],
      'La Lastra 3 Stone Random': ['Pewter', 'Desert Tan', 'Charcoal', 'Cambridge Blend', 'Breitenbush Blend'],
      'La Pietra Quattro Random': ['Cambridge Blend', 'Jamestown Blend', 'Breitenbush Blend', 'Columbia Blend', 'Umbria Blend'],
      'La Pietra 5 Stone': ['Cambridge Blend', 'Jamestown Blend', 'Columbia Blend', 'Breitenbush Blend', 'Umbria Blend'],
      'La Pietra 3 Stone Running Bond': ['Cambridge Blend', 'Jamestown Blend', 'Columbia Blend', 'Breitenbush Blend', 'Umbria Blend'],
      'La Pietra Italia': ['Cambridge Blend', 'Jamestown Blend', 'Columbia Blend', 'Breitenbush Blend', 'Umbria Blend'],
      'La Pietra Modified Marseilles': ['Cambridge Blend', 'Jamestown Blend', 'Columbia Blend', 'Breitenbush Blend', 'Umbria Blend'],
      'La Pietra Muster K': ['Cambridge Blend', 'Jamestown Blend', 'Columbia Blend', 'Breitenbush Blend', 'Umbria Blend'],
      'La Pietra Opus II': ['Cambridge Blend', 'Jamestown Blend', 'Columbia Blend', 'Breitenbush Blend', 'Umbria Blend'],
      'La Pietra Palatial Versailles': ['Cambridge Blend', 'Jamestown Blend', 'Columbia Blend', 'Breitenbush Blend', 'Umbria Blend'],
      'La Pietra 2 Stone Running Bond': ['Cambridge Blend', 'Jamestown Blend', 'Columbia Blend', 'Breitenbush Blend', 'Umbria Blend'],
      'La Pietra Quattro Moderna Random': ['Cambridge Blend', 'Jamestown Blend', 'Umbria Blend'],
      'La Pietra Moderna 5 Stone': ['Cambridge Blend', 'Jamestown Blend', 'Umbria Blend'],
      'La Pietra Moderna 3 Stone Running Bond': ['Cambridge Blend', 'Jamestown Blend', 'Umbria Blend'],
      'La Pietra Moderna Italia': ['Cambridge Blend', 'Jamestown Blend', 'Umbria Blend'],
      'La Pietra Moderna Modified Marseilles': ['Cambridge Blend', 'Jamestown Blend', 'Umbria Blend'],
      'La Pietra Moderna Muster K': ['Cambridge Blend', 'Jamestown Blend', 'Umbria Blend'],
      'La Pietra Moderna Opus II': ['Cambridge Blend', 'Jamestown Blend', 'Umbria Blend'],
      'La Pietra Moderna Palatial Versailles': ['Cambridge Blend', 'Jamestown Blend', 'Umbria Blend'],
      'La Pietra Moderna 2 Stone Running Bond': ['Cambridge Blend', 'Jamestown Blend', 'Umbria Blend'],
      Linen: ['Charcoal', 'Desert Tan', 'Pewter'],
      'Milana 3 Stone Small': ['Jamestown Blend', 'Cambridge Blend'],
      'Milana 3 Stone': ['Jamestown Blend', 'Cambridge Blend'],
      'Milana 4 Stone': ['Jamestown Blend', 'Cambridge Blend'],
      'Milana Muster K': ['Jamestown Blend', 'Cambridge Blend'],
      'Milana Running Bond': ['Jamestown Blend', 'Cambridge Blend'],
      'Milana Random': ['Jamestown Blend', 'Cambridge Blend'],
      'Roma Stone': ['Jamestown Blend', 'Cambridge Blend', 'Charcoal', 'Pewter'],
    },
    Borders: {
      'Holland (Soldier)': [
        {
          Standard: ['Pewter', 'Charcoal', 'Cambridge Blend', 'Boston Blend', 'Jamestown Blend', 'Western Red', 'Desert Tan'],
        },
        {
          Granite: ['Cambridge Blend', 'Jamestown Blend', 'Columbia Blend', 'NW Slate Blend', 'Charcoal'],
        },
        { Roca: ['Charcoal'] },
      ],
      'Holland (Sailor)': [
        {
          Standard: ['Pewter', 'Charcoal', 'Cambridge Blend', 'Boston Blend', 'Jamestown Blend', 'Western Red', 'Desert Tan'],
        },
        {
          Granite: ['Cambridge Blend', 'Jamestown Blend', 'Columbia Blend', 'NW Slate Blend', 'Charcoal'],
        },
        { Roca: ['Charcoal'] },
      ],
      'Half Holland': [
        {
          Standard: ['Pewter', 'Charcoal', 'Cambridge Blend', 'Boston Blend', 'Jamestown Blend'],
        },
        {
          Granite: ['Cambridge Blend', 'Jamestown Blend', 'Columbia Blend', 'Charcoal'],
        },
        { Roca: ['Charcoal'] },
      ],
      'Holland Slimline (Soldier)': ['Pewter', 'Charcoal', 'Boston Blend', 'Jamestown Blend'],
      'Holland Slimline (Sailor)': ['Pewter', 'Charcoal', 'Boston Blend', 'Jamestown Blend'],
      'Camino Lg Rectangle (Soldier)': [
        {
          Cobble: ['Pewter', 'Charcoal', 'Cambridge Blend', 'Boston Blend', 'Jamestown Blend', 'Britewood Blend', 'Columbia Blend', 'Umbria Blend'],
        },
        {
          Roca: ['Charcoal', 'Cambridge Blend', 'Jamestown Blend', 'Columbia Blend'],
        },
      ],
      'Camino Lg Rectangle (Sailor)': [
        {
          Cobble: ['Pewter', 'Charcoal', 'Cambridge Blend', 'Boston Blend', 'Jamestown Blend', 'Britewood Blend', 'Columbia Blend', 'Umbria Blend'],
        },
        {
          Roca: ['Charcoal', 'Cambridge Blend', 'Cambridge Blend', 'Jamestown Blend', 'Columbia Blend'],
        },
      ],
      'Camino Square': [
        {
          Cobble: ['Pewter', 'Charcoal', 'Cambridge Blend', 'Boston Blend', 'Jamestown Blend', 'Britewood Blend', 'Columbia Blend', 'Umbria Blend'],
        },
        { Roca: ['Jamestown Blend', 'Columbia Blend'] },
      ],
      'Camino Sm Rectangle (Soldier)': [
        {
          Cobble: ['Pewter', 'Charcoal', 'Cambridge Blend', 'Boston Blend', 'Jamestown Blend', 'Britewood Blend', 'Columbia Blend', 'Umbria Blend'],
        },
        {
          Roca: ['Cambridge Blend', 'Jamestown Blend', 'Columbia Blend'],
        },
      ],
      'Camino Sm Rectangle (Sailor)': [
        {
          Cobble: ['Pewter', 'Charcoal', 'Cambridge Blend', 'Boston Blend', 'Jamestown Blend', 'Britewood Blend', 'Columbia Blend', 'Umbria Blend'],
        },
        { Roca: ['Cambridge Blend', 'Jamestown Blend', 'Columbia Blend'] },
      ],
      'Cobble Slimline Rectangle (Soldier)': ['Charcoal', 'Cambridge Blend', 'Jamestown Blend', 'Columbia Blend'],
      'Cobble Slimline Rectangle (Sailor)': ['Charcoal', 'Cambridge Blend', 'Jamestown Blend', 'Columbia Blend'],
      'Cobble Slimline Square': ['Cambridge Blend', 'Jamestown Blend', 'Columbia Blend'],
      'Double Park (Sailor)': ['Cambridge Blend', 'Charcoal', 'Pewter', 'Jamestown Blend'],
      'Double Park (Soldier)': ['Cambridge Blend', 'Charcoal', 'Pewter', 'Jamestown Blend'],
      Arena: [
        {
          Standard: ['Pewter', 'Desert Tan', 'Charcoal', 'Cambridge Blend', 'Jamestown Blend'],
        },
        {
          Granite: ['Cambridge Blend', 'Jamestown Blend', 'Columbia Blend', 'NW Slate Blend'],
        },
      ],
      'Plaza (Soldier)': [
        {
          Standard: ['Cambridge Blend', 'Boston Blend', 'Jamestown Blend'],
        },
        {
          Cobble: ['Pewter', 'Charcoal', 'Cambridge Blend', 'Jamestown Blend', 'Charleston Blend', 'Columbia Blend'],
        },
        {
          Granite: ['Cambridge Blend', 'Jamestown Blend', 'Columbia Blend', 'NW Slate Blend', 'Charcoal'],
        },
      ],
      'Plaza (Sailor)': [
        {
          Standard: ['Cambridge Blend', 'Boston Blend', 'Jamestown Blend'],
        },
        {
          Cobble: ['Pewter', 'Charcoal', 'Cambridge Blend', 'Jamestown Blend', 'Charleston Blend', 'Columbia Blend'],
        },
        {
          Granite: ['Cambridge Blend', 'Jamestown Blend', 'Columbia Blend', 'NW Slate Blend', 'Charcoal'],
        },
      ],
      Park: [
        {
          Standard: ['Cambridge Blend', 'Boston Blend', 'Jamestown Blend'],
        },
        {
          Cobble: ['Pewter', 'Charcoal', 'Cambridge Blend', 'Jamestown Blend', 'Charleston Blend', 'Columbia Blend'],
        },
        {
          Granite: ['Cambridge Blend', 'Jamestown Blend', 'Columbia Blend', 'NW Slate Blend', 'Charcoal'],
        },
        {
          Roca: ['Cambridge Blend', 'Jamestown Blend', 'Columbia Blend'],
        },
      ],
      'Milana Small Rectangle (Sailor)': ['Jamestown Blend', 'Cambridge Blend', 'Charcoal'],
      'Milana Small Rectangle (Soldier)': ['Jamestown Blend', 'Cambridge Blend', 'Charcoal'],
      'Monaco Coping Stone': ['Charcoal', 'Cambridge Blend', 'Jamestown Blend'],
    },
    MultipleWallBlocks: {
      'Chateau 3 Stone Running Bond (Small)': ['Charcoal', 'Cambridge Blend', 'Sandstone Blend', 'Breitenbush Blend', 'Columbia Blend'],
      'Chateau 3 Stone Running Bond (Large)': ['Charcoal', 'Cambridge Blend', 'Sandstone Blend', 'Breitenbush Blend', 'Columbia Blend'],
      'Chateau Diviso 3 Stone Running Bond (Large)': ['Charcoal', 'Olympian Blend', 'Cambridge Blend'],
      'Chateau Moderna 3 Stone Running Bond (Large)': ['Charcoal', 'Cambridge Blend', 'Columbia Blend'],
      'Chateau 4 Stone Running Bond': ['Charcoal', 'Cambridge Blend', 'Sandstone Blend', 'Breitenbush Blend', 'Columbia Blend'],
      'Chateau 4 Stone Random': ['Charcoal', 'Cambridge Blend', 'Sandstone Blend', 'Breitenbush Blend', 'Columbia Blend'],
    },
    SinglePavingStones: {
      'Arena / City Square': ['Pewter', 'Desert Tan', 'Charcoal', { Arena: ['Cambridge Blend', 'Jamestown Blend'] }],
      Camino: {
        Cobble: ['Pewter', 'Charcoal', 'Cambridge Blend', 'Boston Blend', 'Jamestown Blend', 'Britewood Blend', 'Columbia Blend', 'Umbria Blend'],
        Roca: ['Columbia Blend', 'Jamestown Blend', { 'Large Rec': ['Charcoal', 'Cambridge Blend'] }, { Square: ['Cambridge Blend'] }],
      },
      'Camino Permeare': ['Cambridge Blend', 'Jamestown Blend', 'Columbia Blend'],
      'Cobble Slimline': [{ 'Large Rec': ['Charcoal'] }, 'Cambridge Blend', 'Jamestown Blend', 'Columbia Blend'],
      'Granite Stone': [
        'Cambridge Blend',
        'Jamestown Blend',
        'Columbia Blend',
        { Arena: ['NW Slate Blend'] },
        { Holland: ['NW Slate Blend', 'Charcoal'] },
        { Park: ['NW Slate Blend', 'Charcoal'] },
        { Plaza: ['NW Slate Blend', 'Charcoal'] },
        { 'Half Holland': ['Charcoal'] },
      ],
      'Holland Stone / Half Holland Stone': {
        Standard: ['Pewter', 'Charcoal', 'Cambridge Blend', 'Boston Blend', 'Jamestown Blend', { Holland: ['Western Red', 'Desert Tan'] }],
        Roca: ['Charcoal'],
      },
      'Holland Slimline': ['Pewter', 'Charcoal', 'Boston Blend', 'Jamestown Blend'],
      'Holland / Park Permeare': [{ 'Holland Permeare': ['Pewter'] }, 'Jamestown Blend', 'Columbia Blend'],
      Milana: ['Jamestown Blend', 'Cambridge Blend', { 'Small Rectangle': ['Charcoal'] }],
      'La Lastra': ['Pewter', 'Desert Tan', 'Charcoal', 'Cambridge Blend', 'Breitenbush Blend'],
      'La Pietra': ['Cambridge Blend', 'Jamestown Blend', 'Columbia Blend'],
      'La Pietra Moderna': ['Cambridge Blend', 'Jamestown Blend', 'Columbia Blend'],
      'Park / Plaza': {
        Standard: ['Charcoal', 'Pewter', 'Cambridge Blend', 'Boston Blend', 'Jamestown Blend'],
        Roca: ['Cambridge Blend', 'Jamestown Blend', 'Columbia Blend'],
        Cobble: ['Pewter', 'Charcoal', 'Cambridge Blend', 'Jamestown Blend', 'Charleston Blend', 'Columbia Blend'],
      },
      'Double Park': {
        Standard: ['Cambridge Blend', 'Charcoal', 'Pewter', 'Jamestown Blend'],
      },
      'Turf Stone': ['Pewter'],
      'VS-5': ['Pewter'],
      Villa: ['Charcoal', 'Cambridge Blend', 'Jamestown Blend', 'Pewter'],
    },
    SingleWallBlocks: {
      Chateau: ['Charcoal', 'Cambridge Blend', 'Sandstone Blend', 'Breitenbush Blend', 'Columbia Blend'],
      'Chateau Diviso': ['Olympian Blend', 'Cambridge Blend', 'Charcoal'],
      'Chateau Moderna': ['Charcoal', 'Cambridge Blend', 'Columbia Blend'],
      Tegula: ['Charcoal', 'Cambridge Blend', 'Sandstone Blend', 'Columbia Blend'],
      Murata: ['Pewter', 'Olympian Blend', 'Cambridge Blend'],
      'Slope Block': ['Pewter'],
      'Nursery Stones': ['Pewter', 'Tan', 'Charcoal', 'Red', 'Columbia'],
      'Gradino Wall Block': ['Pewter', 'Umbria Blend', 'Charcoal', 'Cambridge Blend', 'Olympian Blend'],
      'Western Manor Flat Face': ['Gray', 'Khaki', 'Charcoal', 'Antique Blend'],
      'Western Manor Radius Face': ['Gray', 'Khaki', 'Charcoal', 'Antique Blend', 'West Valley Blend'],
    },
    Rotundo: {
      Rotundo: [{ Standard: ['Cambridge Blend', 'Jamestown Blend'] }, { Cobble: ['Cambridge Blend', 'Jamestown Blend', 'Charleston Blend', 'Britewood Blend', 'Columbia Blend'] }],
      'Roca Rotundo': ['Jamestown Blend', 'Columbia Blend'],
      'Alpine Rotundo': ['Jamestown Blend', 'Columbia Blend'],
    },
    FireFeatures: {
      'Deschutes Fireplace Kit': ['Charcoal', 'Cambridge Blend', 'Sandstone Blend', 'Breitenbush Blend', 'Columbia Blend'],
      'Rogue Fireplace Kit': ['Charcoal', 'Cambridge Blend', 'Sandstone Blend', 'Breitenbush Blend', 'Columbia Blend'],
      '50" Chateau Fire Pit Kit': ['Charcoal', 'Cambridge Blend', 'Sandstone Blend', 'Breitenbush Blend', 'Columbia Blend'],
      '50" Chateau Diviso Fire Pit Kit': ['Cambridge Blend', 'Olympian Blend'],
      '40" Tegula Fire Pit Kit': ['Charcoal', 'Cambridge Blend', 'Sandstone Blend', 'Columbia Blend'],
    },
    Steps: {
      'Tegula Step': ['Charcoal', 'Cambridge Blend', 'Sandstone Blend', 'Columbia Blend'],
      'Gradino Step Block': ['Charcoal', 'Pewter', 'Desert Tan', 'Olympian Blend'],
      'Murata Variable Step': ['Pewter', 'Olympian Blend', 'Cambridge Blend'],
      'Murata Step Tread': ['Pewter', 'Charcoal'],
    },
    Caps: {
      'Chateau Diviso Cap': ['Pewter', 'Olympian Blend', 'Cambridge Blend', 'Charcoal'],
      'Jumbo / Nursery Caps': ['Pewter', 'Tan', 'Charcoal', 'Columbia Blend'],
      'Murata Capello': ['Pewter', 'Desert Sand', 'Charcoal'],
      'Versa Cap': ['Pewter', 'Tan', 'Charcoal', 'Olympian Blend'],
    },
  },
  surfaceOptions: {
    MultiplePavingStones: {
      'Park / Plaza Muster K': ['Standard', 'Cobble', 'Granite', 'Roca'],
      'Park / Plaza Running Bond': ['Standard', 'Cobble', 'Granite', 'Roca'],
      'Granite / Leiden Brickweave': ['Granite', 'Leiden'],
      'Granite / Leiden 3 Stone': ['Granite', 'Leiden'],
      'Granite / Leiden 4 Stone': ['Granite', 'Leiden'],
      'Granite / Leiden 5 Stone Pattern (Multistone)': ['Granite', 'Leiden'],
      'Granite / Leiden 5 Stone Pattern (Individual Pieces)': ['Granite', 'Leiden'],
      'Granite / Leiden 5 Stone Random': ['Granite', 'Leiden'],
      'Granite / Leiden 3 Stone Small': ['Granite', 'Leiden'],
      'Camino Muster K': ['Cobble', 'Roca'],
      'Leiden 6 Stone': ['Standard'],
      'Leiden Paladio': ['Standard'],
      'Camino 3 Stone "I"': ['Cobble', 'Roca'],
      'Camino 2 Stone Running Bond': ['Cobble', 'Roca'],
      'Camino 3 Stone Running Bond': ['Cobble', 'Roca'],
      'Milana 3 Stone Small': ['Milana', 'Milana Moderna'],
      'Milana 3 Stone': ['Milana', 'Milana Moderna'],
      'Milana 4 Stone': ['Milana', 'Milana Moderna'],
      'Milana Muster K': ['Milana', 'Milana Moderna'],
      'Milana Running Bond': ['Milana', 'Milana Moderna'],
      'Milana Random': ['Milana', 'Milana Moderna'],
    },
    SinglePavingStones: {
      'Arena / City Square': ['Standard'],
      Camino: ['Cobble', 'Roca'],
      'Camino Permeare': [],
      'Cobble Slimline': [],
      'Granite Stone': [],
      'Holland Stone / Half Holland Stone': ['Standard', 'Roca'],
      'Holland Slimline': [],
      'Holland / Park Permeare': [],
      Milana: ['Milana', 'Milana Moderna'],
      'La Lastra': [],
      'La Pietra': [],
      'La Pietra Moderna': [],
      'Park / Plaza': ['Standard', 'Cobble', 'Roca'],
      'Double Park': ['Standard'],
      'Turf Stone': [],
      Villa: [],
      'VS-5': [],
    },
    Borders: {
      Arena: ['Standard', 'Granite'],
      'Camino Lg Rectangle (Soldier)': ['Cobble', 'Roca'],
      'Camino Lg Rectangle (Sailor)': ['Cobble', 'Roca'],
      'Camino Square': ['Cobble', 'Roca'],
      'Camino Sm Rectangle (Soldier)': ['Cobble', 'Roca'],
      'Camino Sm Rectangle (Sailor)': ['Cobble', 'Roca'],
      Park: ['Standard', 'Cobble', 'Granite', 'Roca'],
      'Plaza (Soldier)': ['Standard', 'Cobble', 'Granite'],
      'Plaza (Sailor)': ['Standard', 'Cobble', 'Granite'],
      'Half Holland': ['Standard', 'Granite', 'Roca'],
      'Holland (Sailor)': ['Standard', 'Granite', 'Roca'],
      'Holland (Soldier)': ['Standard', 'Granite', 'Roca'],
      'Milana Small Rectangle (Sailor)': ['Standard', 'Moderna'],
      'Milana Small Rectangle (Soldier)': ['Standard', 'Moderna'],
    },
    FireFeatures: {
      'Deschutes Fireplace Kit': ['No Woodbox', '1 Woodbox', '2 Woodboxes'],
      'Rogue Fireplace Kit': ['No Woodbox', '1 Woodbox', '2 Woodboxes'],
      '50" Chateau Fire Pit Kit': ['Pewter Cap', 'Charcoal Cap', 'Olympian Blend Cap', 'Cambridge Blend Cap', 'No Cap'],
      '50" Chateau Diviso Fire Pit Kit': ['Pewter Cap', 'Charcoal Cap', 'Olympian Blend Cap', 'Cambridge Blend Cap', 'No Cap'],
    },
    Rotundo: {
      Rotundo: ['Standard', 'Cobble'],
      'Roca Rotundo': [],
      'Alpine Rotundo': [],
    },
  },
  rotundoWeightInfo: {
    Rotundo: {
      RotundoStones: 333,
      ParkStones: 12,
    },
    'Roca Rotundo': {
      RotundoStones: 333,
      ParkStones: 12,
    },
    'Alpine Rotundo': {
      AlpineRotundo: 356,
      SingleStone: 30,
      LargeRectangle: 25,
      LargeSquare: 37.5,
    },
  },
  editItemDetails: null,
};

const rootReducer = (state = initState, action) => {
  if (action.type === 'ADDEDITITEMS') {
    return {
      ...state,
      editItemDetails: action.item,
    };
  }
  return state;
};

export default rootReducer;
